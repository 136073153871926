import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { Competencia } from "../../../../Interfaces/requisitos/observadosInterface";
import { FormField } from "../../../Layouts/Form";
import * as S from "./Competencia.styled";

type Props = {
  competencia: Competencia;
  index: number;
};

export const CompetenciaItem = ({ competencia, index }: Props) => {
  const niveles = [
    { value: "En proceso de aprendizaje", label: "Insuficiente" },
    { value: "Principiante", label: "Suficiente" },
    { value: "Competente", label: "Competente" },
  ];

  return (
    <AccordionItem p={5}>
      {({ isExpanded }) => (
        <>
          <AccordionButton>
            <Flex
              flex={1}
              gap={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text>
                C{index + 1}: {competencia.nombre}
              </Text>
              {competencia.nivel && <Badge>{competencia.nivel}</Badge>}
            </Flex>
          </AccordionButton>
          <AccordionPanel>
            <Box textAlign={"center"}>
              {isExpanded && (
                <S.CompetenciaModule>
                  <Flex alignItems={"center"}>
                    <FormField
                      name={`competencias[${index}].nivel`}
                      inputType="radio"
                      options={niveles}
                      flexDirection={"column"}
                      gap={5}
                      padding={5}
                    />
                  </Flex>
                  <Box textAlign={"left"} className={"checkbox"}>
                    <FormField
                      name={`competencias[${index}].marcadores`}
                      inputType="checkbox"
                      options={competencia.niveles[0].matrices.map(
                        (marcador) => ({
                          value: marcador.marcador,
                          label: marcador.marcador,
                        })
                      )}
                      flexDirection={"column"}
                    />
                  </Box>
                </S.CompetenciaModule>
              )}
            </Box>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
