import { useContext } from "react";
import { Accordion, Alert, AlertIcon } from "@chakra-ui/react";
import { BuscadorClasesContext } from "../../Context/buscadorClasesContext";
import { useFormaciones } from "../../Hooks/Formaciones/useFormaciones";
import { MainTitle } from "../Layouts/MainTitle";
import { Formacion } from "./Formacion";
import { BuscadorCursos } from "../Layouts/BuscadorCursos";
import * as Yup from "yup";

export const FormacionesPage = () => {
  const { estadosAcademicos } = useFormaciones();
  const { cursos, isLoading, errors } = useContext(BuscadorClasesContext);
  const validation = Yup.object().shape({});
  return (
    <div>
      <MainTitle title="Mis Formaciones" />

      <BuscadorCursos
        initialValues={{}}
        validation={validation}
        group={{
          options: [
            { value: "1", label: "Carrera" },
            { value: "5", label: "Posgrados" },
            { value: "11", label: "Carreras Cortas" },
          ],
          isDisabled: false,
        }}
        mb={5}
      />

      {!isLoading ? (
        cursos.length ? (
          <Accordion allowToggle>
            {cursos.map((formacion, key) => (
              <Formacion
                formacion={formacion}
                estadosCursada={estadosAcademicos}
                key={key}
              />
            ))}
          </Accordion>
        ) : (
          errors && (
            <Alert status={"info"} variant={"left-accent"}>
              <AlertIcon />
              No tienes formaciones disponibles
            </Alert>
          )
        )
      ) : null}
    </div>
  );
};
