import { useToast } from "@chakra-ui/react";
import { useEffect, useReducer, createContext, useContext } from "react";
import axonApi from "../Api/axonApi";
import {
  ContextProps,
  OptionsInterface,
  ServiciosInterface,
} from "../Interfaces/interfaces";
import { CursosInterface } from "../Interfaces/sessionInterface";
import { addQueryParamsToUrl, clearData } from "../Service/UtilsService";
import { buscadorCursosReducer } from "./buscadorCursosReducer";
import { SessionContext } from "./session/SessionContext";

const CARRERA = 1;
const POSGRADOS = 5;
const CARRERA_CORTA = 11;
const DEFAULT_VALUE = { value: "0", label: "Todos los servicios" };

export interface BuscadorCursosInterface {
  cursos: CursosInterface[];
  servicios: { [k: string]: OptionsInterface[] };
  allServices: OptionsInterface[];
  isLoading: boolean;
  errors: boolean;
  getCursos: (values: any) => void;
  fetchServiciosByGrupo: (idGrupo?: number) => void;
}

export const BuscadorClasesContext = createContext(
  {} as BuscadorCursosInterface
);

const initialValues = {
  cursos: [],
  servicios: {
    [CARRERA]: [],
    [POSGRADOS]: [],
    [CARRERA_CORTA]: [],
  },
  allServices: [DEFAULT_VALUE],
  isLoading: false,
  errors: false,
  getCursos: () => {},
  fetchServiciosByGrupo: () => {},
};

export const BuscadorClasesProvider = ({ children }: ContextProps) => {
  const { session } = useContext(SessionContext);
  const [state, dispatch] = useReducer(buscadorCursosReducer, initialValues);
  const toast = useToast();

  useEffect(() => {
    if (session?.id) {
      fetchServiciosByGrupo(CARRERA);
      fetchServiciosByGrupo(POSGRADOS);
      fetchServiciosByGrupo(CARRERA_CORTA);
    }
    // eslint-disable-next-line
  }, [session]);

  const getCursos = async (values: any) => {
    dispatch({ type: "setLoading", payload: true });
    const parameters = { ...values };
    const url = addQueryParamsToUrl(
      `master/profesores/v2/cursos`,
      clearData(parameters, "0")
    );

    try {
      const { data } = await axonApi.get<{ cursos: CursosInterface[] }>(url);
      dispatch({ type: "setCursos", payload: data.cursos });
    } catch (error) {
      dispatch({ type: "setLoading", payload: false });
      toast({
        title: "Error",
        description: "Error al cargar los cursos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchServiciosByGrupo = async (grupo: number = 1) => {
    if (!state.servicios[grupo].length) {
      try {
        const { data } = await axonApi.get<ServiciosInterface[]>(
          `/academico/grupos/${grupo}/servicios`
        );

        if (!data.length || data.length <= 0) {
          dispatch({
            type: "setServicesByGroup",
            payload: { idServicio: grupo, data: [] },
          });
        } else {
          const servicios = data
            .filter((servicio) => !["3", "10"].includes(servicio.id))
            .map((servicio) => {
              return {
                value: servicio.id,
                label: servicio.nombre,
              };
            });

          dispatch({ type: "setAllServices", payload: servicios });
          dispatch({
            type: "setServicesByGroup",
            payload: {
              idServicio: grupo,
              data: [DEFAULT_VALUE, ...servicios],
            },
          });
        }
      } catch (erro) {
        dispatch({
          type: "setServicesByGroup",
          payload: { idServicio: grupo, data: [] },
        });
      }
    }
  };

  return (
    <BuscadorClasesContext.Provider
      value={{ ...state, getCursos, fetchServiciosByGrupo }}
    >
      {children}
    </BuscadorClasesContext.Provider>
  );
};
